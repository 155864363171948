:root {
    --text-color: black;
    --border-color: #efefef;
    --overlay-bg-color: rgba(0, 0, 0, 0.5);
    --overlay-text-color: aliceblue;
}

[data-theme='dark'] {
    --text-color: #dfdfdf;
    --border-color: #676767;
    --overlay-bg-color: rgba(255, 255, 255, 0.5);
    --overlay-text-color: black;

}

.blog_container {
    background-color: var(--background-color);
    border: var(--border-color) 1px solid;
    border-radius: 4px;
}

.blog_title {
    font-size: 20px;
    font-weight: 600;
}

.blog_date {
    font-size: 10px;
    font-weight: 300;
}

.blogdescription {
    font-size: 13px;
    line-height: 5px;
    min-height: 45px;
}

.blogfeatured {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #1473E6;
    background: #DAE9FC;
    border-radius: 4px;
    padding: 4px 12px;
    cursor: pointer;
}


.notblogfeatured {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #e61414;
    background: #fcdada;
    border-radius: 4px;
    padding: 4px 12px;
    cursor: pointer;
}