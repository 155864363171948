body {

  transition: all 0.5s !important;

  overscroll-behavior-y: none;
}

:root {
  --background-color: white;
  --text-color: black;
  --border-color: rgba(0, 0, 0, 0);
  --overlay-bg-color: rgba(0, 0, 0, 0.5);
  --overlay-text-color: aliceblue;
}

[data-theme='dark'] {
  --background-color: #181A1B;
  --text-color: #D2D0CA;
  --border-color: rgba(255, 255, 255, 0.6);
  --overlay-bg-color: rgba(255, 255, 255, 0.5);
  --overlay-text-color: black;
}

/* div {
  transition: all 0.5s !important;
} */

.popup-content {
  background: var(--background-color) !important;
}

.body {
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
  transition: all 0.5s !important;
  background: url("assets/images/noise.svg");
  background-repeat: repeat;
  /* background-blend-mode: screen; */
  /* background-color: #cccccc;; */
}

.image_picker_box {
  cursor: pointer;
  width: 354px !important;
  height: 354px !important;
  border: 2px solid var(--border-color);
  transition: all 0.5s !important;
  text-align: center;
  justify-content: center;
  border-radius: 20px;
  content: '';
  position: relative;
}

.image_picker_box:hover {
height: 350px !important;
}

.image_picker_box:focus {
  height: 350px !important;

}

.image_picker_image {
  position: absolute;
  /* width: 100%; */
  height: 100%;
  border-radius: 20px;
}

.picker_text {
  text-align: center;
  border: 6px dotted var(--text-color);
  transition: all 0.5s !important;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.focus-overlay {
  position: absolute;
  opacity: 0;
  min-width: 100%;
  height: 100%;
  border-radius: 20px;
  justify-content: center;
  z-index: 2;
}

sociallink{
  cursor: pointer;
}

.focus-overlay:hover {
  opacity: 1 !important;
  background-color: var(--overlay-bg-color);
  transition: all 0.5s !important;
  cursor: pointer;
  color: var(--overlay-text-color);
  text-align: center;
}

.focus-overlay:focus {
  opacity: 100%;
  background-color: var(--overlay-bg-color);
  z-index: 2;
  cursor: pointer;
  color: var(--overlay-text-color);
  transition: all 0.5s !important;
  text-align: center;
}

.focus-overlay:focus-visible {
  opacity: 100%;
  background-color: var(--overlay-bg-color);
  transition: all 0.5s !important;
  z-index: 2;
  cursor: pointer;
  color: var(--overlay-text-color);
  text-align: center;
}

.pro-sidebar .pro-menu a:before {
  content: '';
  position: relative !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}


::-webkit-scrollbar-thumb {
  background: #FFC724;
  border-radius: 2px;
}

.content-body {
  transition: all 0.5s !important;
}