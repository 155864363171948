/* ============================================================================================== 
SED Innovations
https://sed.am
https://mkrtchyan.ga
================================================================================================= */
* {
    margin: 0;
    padding: 0;
}

.container {
    overflow: hidden;
}

@keyframes move-twink-back {
    from {
        background-position: 0 0;
    }

    to {
        background-position: -10000px 5000px;
    }
}

@-webkit-keyframes move-twink-back {
    from {
        background-position: 0 0;
    }

    to {
        background-position: -10000px 5000px;
    }
}

@-moz-keyframes move-twink-back {
    from {
        background-position: 0 0;
    }

    to {
        background-position: -10000px 5000px;
    }
}

@-ms-keyframes move-twink-back {
    from {
        background-position: 0 0;
    }

    to {
        background-position: -10000px 5000px;
    }
}

@keyframes move-clouds-back {
    from {
        background-position: 0 0;
    }

    to {
        background-position: 10000px 0;
    }
}

@-webkit-keyframes move-clouds-back {
    from {
        background-position: 0 0;
    }

    to {
        background-position: 10000px 0;
    }
}

@-moz-keyframes move-clouds-back {
    from {
        background-position: 0 0;
    }

    to {
        background-position: 10000px 0;
    }
}

@-ms-keyframes move-clouds-back {
    from {
        background-position: 0;
    }

    to {
        background-position: 10000px 0;
    }
}

.stars, .twinkling {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;
}

.stars {
    /* background-color: linear-gradient(top to bottom, #82addb 0%, #ebb2b1 100%); */
    /* background: url(../../../public/assets/stars.png) repeat top center; */
    z-index: 0;
}

.twinkling {
    /* background: transparent url(../../../public/assets/twinkling.png) repeat top center; */
    z-index: 0;
    -moz-animation: move-twink-back 200s linear infinite;
    -ms-animation: move-twink-back 200s linear infinite;
    -o-animation: move-twink-back 200s linear infinite;
    -webkit-animation: move-twink-back 200s linear infinite;
    animation: move-twink-back 200s linear infinite;
}