:root {
    --text-color: #010a14;
    --shadow-color: #d7d7d7;
    --text-shadow-color: rgba(0, 0, 0, 0.25);
    --summary-title-color: #8E8E8E;
    --summary-value-color: #2C2C2C;
    --views-text-color: #4B4B4B;
    --border-bottom-color: #F5F5F5;
    --hover-color: #D1E7DE;
}

[data-theme='dark'] {
    --text-color: #dfdfdf;
    --shadow-color: #888;
    --text-shadow-color: rgba(255, 255, 255, 0.25);
    --summary-title-color: #dfdfdf;
    --summary-value-color: #efefef;
    --views-text-color: #ffffff;
    --border-bottom-color: #444;
    --hover-color: #008a51;
}

text {
    fill: var(--text-color) !important;

}

#dashboadtxt {
    font-size: 48px;
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    color: var(--text-color);
    text-shadow: 0px 4px 4px var(--text-shadow-color);
}


.summary {
    width: 200px;
    margin-right: 10px;
    border-radius: 6px;
    padding: 10px;
    box-shadow: 0px 0px 4px var(--shadow-color);
}

.summary-title {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: var(--summary-title-color);
}

.summary-value {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 33px;
    color: var(--summary-value-color);
    flex: none;
    order: 0;
    flex-grow: 0;
}

.summary-indicator {
    width: 100px;
    border-radius: 10px;
    font-size: 10px;
}

.negative {
    color: #B52A54;
    background-color: #FE9F99;
}

.positive {
    color: #489019;
    background-color: #C3EE83;
}

.popular-article {
    font-size: 20px;
    font-weight: 600;
    padding: 17px 25px;
    border-bottom: 1px solid #DDDDDD;

}

.popular-article-title {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: var(--summary-value-color);
}

.popular-article-date {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #9F9F9F;
}

.popular-article-item {
    padding: 5px 23px;
    border-bottom: 1px solid var(--border-bottom-color);
}

.popular-article-item:hover {
    cursor: pointer;
    background-color: var(--hover-color);
}

.title-txt {
    font-size: 16px;
    font-weight: 500;
}

.views-txt {
    font-size: 14px;
    color: var(--views-text-color);
    padding: 2px 12px !important;
}

.article-image {
    width: 50px;
    height: 50px;
    /* color-purple-100 */
    background: var(--background-color);
    border-radius: 6px;
}

.featured-box {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #1473E6;
    background: #DAE9FC;
    border-radius: 4px;
    padding: 4px 12px;
}

.chart-color-box {
    height: 13px;
    width: 13px;
    border-radius: 2px;
    margin-right: 6px;
}

.chart-color-text {
    font-size: 13px;
    color: var(--text-color);
    margin-right: 30px;
}

.pro-icon-wrapper {
    background-color: #008a51 !important;
}