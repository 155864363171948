.sociallink-title {
    font-weight: 800;
}

.sociallinkbox {
    max-width: 200px;
    padding: 20px;
    border: 1px solid #EFEFEF;
    border-radius: 5px;
}


.sociallinktxt {
    font-size: 12px;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
}