:root {
    --side-bar-shadow-color: rgba(0, 0, 0, 0.15);
}

[data-theme='dark'] {

    --side-bar-shadow-color: rgba(255, 255, 255, 0.15);
}

#header .pro-sidebar {
    height: 100vh;
}

#header .closemenu {
    color: var(--text-color);
    position: absolute;
    right: 0;
    z-index: 9999;
    line-height: 20px;
    border-radius: 50%;
    font-weight: bold;
    font-size: 22px;
    top: 55px;
    cursor: pointer;
}

#header .pro-sidebar {
    width: 100%;
    min-width: 100%;
}

#header .pro-sidebar.collapsed {
    width: 90px;
    min-width: 80px;
}

#header .pro-sidebar-inner {
    background-color: var(--background-color);
    color: var(--text-color);
    transition: all 0.5s !important;
    box-shadow: 0.5px 0.866px 2px 0px var(--side-bar-shadow-color);
}

#header .pro-sidebar-inner .pro-sidebar-layout {
    overflow-y: scroll;
}

#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
    font-size: 20px;
    padding: 0 20px;
    color: #000;
    font-weight: bold;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul {
    padding: 0 5px;
}


#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
    color: var(--text-color);
    margin: 10px 0px;
    font-weight: bold;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
    background-color: #448AFF;
    color: rgb(255, 255, 255);
    border-radius: 3px;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item:hover {
    background-color: #FFC724;
    color: #000;
    border-radius: 3px;
}


#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
    color: #000;
}

#header .pro-sidebar-inner .pro-sidebar-layout .active {
    background: #FFC107;
    color: #000 !important;
}

.pro-menu-item.active>.pro-inner-item>.pro-item-content {
    color: #000 !important;

}

#header .logo {
    padding: 20px;
}

@media only screen and (max-width: 720px) {
    html {
        overflow: hidden;
    }
}


.pro-sidebar .pro-menu a:before {
    position: none !important;
}