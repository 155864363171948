.profileimg {
    border-radius: 0px 50px 0px 50px;
    border-width: 6px;
    border-style: solid;
    width: 250px;
    aspect-ratio: 1;
}

.displayimg {
    border-radius: 0px;
}

.nametitle {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 28px;
    font-weight: 500;
}

.username {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    font-size: 14px;
}

.info {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

    font-size: 14px;

}

.editinput {
    width: 100% !important;
    padding: 0% 6px;
    margin: 0%;
    color: #000;
    font-size: 15px;
    font-weight: 400;
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #ababab;
}