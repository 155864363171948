.homebg {
    height: 100vh;
    /* background: url("../../../public/assets/satrun.png"); */

    background: linear-gradient(to bottom, #82addb 0%, #ebb2b1 100%);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.homeglass {
    margin: 2em;
    padding: 2em;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px) saturate(200%);
    -webkit-backdrop-filter: blur(4px) saturate(200%);
    border-radius: 10px;
    border: 2px solid rgba(255, 255, 255, 0.3);
}

input {
    height: 50px;
    width: 60% !important;
    padding: 0 10px;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 300;
    background-color: transparent;
    /* border: 0px solid rgba(255, 255, 255, 0.8); */

    border: 1px solid #4a5568 !important;
    padding-left: 10px;

    padding-right: 10px;
    border-radius: 3px;
    /* border-bottom: 2px solid black !important; */
}


:focus-visible {
    outline: none !important;
}

.greeting-input {
    font-size: 60px;
    font-weight: bold !important;
    align-self: center;
    overflow: visible;
    color: var(--text-color);
    overflow-wrap: break-word;
    word-break: break-word;
    word-wrap: break-word;
    white-space: initial;
    min-height: 38px !important;
    border: 1px solid #4a5568 !important;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 3px;
}

.personal-input {
    font-size: calc(1.3rem + .6vw);
    font-weight: bold !important;
    align-self: center;
    min-height: 40px;
    color: #4a5568;
    border: 1px solid #4a5568 !important; 
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 3px;

}