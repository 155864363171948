.editor_body {
    min-height: 200px;
}

.title-input {
    font-size: 32px;
    font-weight: bold;
    color: var(--text-color) !important;
}

.ReactImagePickerEditor .place-image .image-holder {
    background-color: var(--background-color) !important;
    color: var(--text-color);
    border: 2px var(--border-color) solid;
    border-radius: 8px !important;
}

.material-icons {
    color: var(--text-color) !important;

}