:root {
    --status-text-color: #201F1F;
}

[data-theme='dark'] {
    --status-text-color: #dfdfdf;

}

.status-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 200px;
    line-height: 242px;
    color: #FA7C70;
}

.status-info {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--status-text-color);
}