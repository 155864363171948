.background {
    height: 100vh;
    /* background: linear-gradient(90deg, hsla(159, 75%, 68%, 1) 0%, hsla(259, 75%, 68%, 1) 100%);
    background-size: 200%;
    animation: aurora 8s infinite;
    padding: 3rem; */
    background-color: black;
    overflow: hidden;
    overflow-y: clip;
}

.solar_system {
    position: absolute !important;

}

.login-card {
    padding: 50px 35px;
    max-width: 450px;
    min-width: 250px;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 10px;
    border: 3px solid rgba(255, 255, 255, 0.18);
    color: white;
    position: absolute;
    transform: translate(-50%, -50%);
    right: 50%;
    top: 50%;
}

.form_row {
    min-height: 100vh;
}

form {
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.1);
    /* max-width: 300px; */
    width: 450px;
    border-radius: 10px;
    backdrop-filter: blur(0.5px);
    -webkit-backdrop-filter: blur(0.5px);
    border: 2px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 20px 35px;
    padding-bottom: 60px;
    color: #fff;
}

.login_background>form * {
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
    letter-spacing: 0.5px;
    outline: none;
    border: none;
}

.login_background form h3 {
    font-size: 32px;
    font-weight: 800;
    line-height: 42px;
    text-align: center;

}

.login_background label {
    display: block;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 500;
}

.login_background input {
    display: block;
    height: 50px;
    width: 100% !important;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 7px;
    padding: 0 10px;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 600;
    caret-color: burlywood;
    border: 2px solid rgba(255, 255, 255, 0.5) !important;
}

.login_background>::placeholder {
    color: #e5e5e5;
}


.login_background button {
    margin-top: 50px;
    width: 100%;
    background-color: #ffffff;
    color: #080710;
    padding: 15px 0;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
}