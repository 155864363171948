.containerbox {
    padding: 20px;
    border-bottom: 1px solid #b6b5b5;
    font-size: 20px;
    font-weight: 600;
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 0;
}

#greet {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    /* color-gray-700 */

    color: #6E6E6E;
}

.profilebox {
    width: 64px;
    height: 64px;
    border-radius: 100px;
    border: 2px solid #FFC008;
    background-color: #ffffff;
}

.profileimage {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 100px;
}